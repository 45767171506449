import { useState } from 'react';

export function useFormState(data) {
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(data);
  // Generates an object that matches the form data, but with all the values as false
  const [touchedState, setTouchedState] = useState(Object.keys(formData).reduce((o, key) => ({ ...o, [key]: false}), {}));

  return [submitting, setSubmitting, formData, setFormData, touchedState, setTouchedState, formErrors, setFormErrors]
}

// Sets all the fields to touched, if there are any errors
export function validateFormSubmit(setTouchedState, form_valid) {
  if (!form_valid) {
    setTouchedState(previousState => {
      return Object.keys(previousState).reduce((o, key) => ({ ...o, [key]: true}), {})
    });
  }

  return form_valid;
}

// Returns if there are any errors for fields that the user has changed
export function userErrors(touchedState, formErrors) {
  const touchedError = (key) => touchedState[key];

  return Object.keys(formErrors).some(touchedError)
}
