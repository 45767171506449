// Taken from https://github.com/reactjs/react-rails/issues/1134#issuecomment-1803036505
// Based on https://github.com/reactjs/react-rails/issues/1134#issuecomment-1415112288

// Enables loading of react components with the ReactRailsUJS js
export const viteConstructorRequireContext = function(reqCtx) {
    const componentNameMatcher = className => {
        return path => {
            return (
                path.includes(`/${className}.jsx`) || path.includes(`/${className}/index.jsx`)
            );
        };
    };

    const convertCamelToSnake = (str) => {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g,'$1_').toLowerCase()
    }

    const fromRequireContext = function(reqCtx) {
        return function(className) {
            const snakeClass = convertCamelToSnake(className);
            const componentPath = Object.keys(reqCtx).find(componentNameMatcher(snakeClass));

            const component = reqCtx[componentPath];
            return component.default;
        }
    }

    const fromCtx = fromRequireContext(reqCtx);
    return function(className) {
        let component;
        try {
            // `require` will raise an error if this className isn't found:
            component = fromCtx(className);
        } catch (firstErr) {
            console.error(firstErr);
        }
        return component;
    }
}
