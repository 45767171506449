import { Fragment } from 'react';

const Button = ({ label, ...props }) => {
  return(
    <Fragment>
      <button {...props}>{label}</button>
    </Fragment>
  );
}

export default Button;
