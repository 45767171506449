// Selects a few different things that are considered invalid for numbers, but JS can have a hard time with, this includes
// All but the last `.`
// Any `-` that is not at the start of a string
// Any character that is not `.`, `-` or a digit
export const jsNumberRegex = /\.(?=.*\.)|(?<!^)-|[^\d.-]/g

// Parses a number ensuring that it will never return NAN
export const jsSafeFloat = value => {
  if (!value) {
    return 0
  }
  else if (typeof value === 'number') {
    return value;
  }

  const number = parseFloat(value.toString().replace(jsNumberRegex, ''))
  return isNaN(number) ? 0 : number;
}

// Formats into a currency style, but with no currency character
export const formatCurrency = (value, fixDigits=2) => jsSafeFloat(value).toLocaleString('en-US', { minimumFractionDigits: fixDigits, maximumFractionDigits: fixDigits })
