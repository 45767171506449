import { useState, useEffect } from 'react';
import Select from 'react-select';

function SelectField({ id, name, label, helperMessage, selectOptions, formData, setFormData, touchedState, setTouchedState, formErrors, fixDigits, onChange, ...props }) {
  const errorMessage = touchedState[id] && formErrors[id];
  const helperLabelText = errorMessage || helperMessage;
  const labelText = label || (name || id).replace(/_/g, ' ')

  let wrapperClassList = 'form-group';

  const [selectedOption, setSelectedOption] = useState(selectOptions[id].find(pt => pt.value === formData[id]));

  function handleChange(option) {
    setTouchedState(previousState => {
      return { ...previousState, [id]: true }
    });

    setFormData(previousState => {
      return { ...previousState, [id]: option.value }
    });

    setSelectedOption(option);

    if (onChange) { onChange(option); }
  }

  if (errorMessage) {
    wrapperClassList += ' has-error';
  }

  useEffect(() => {
    if (!formData[id] && selectedOption) {
        setSelectedOption(null);
    }
  }, [formData[id]]);

  return(
    <div className={wrapperClassList}>
      <label className="text-capitalize" htmlFor={id || name}>{labelText}</label>
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={selectOptions[id]}
            className="react-select-container"
            classNamePrefix="react-select"
            {...props}
        />
      <label className='float-right mb-0'>{helperLabelText}</label>
    </div>
  );
}

export default SelectField;
