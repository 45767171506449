import { useLayoutEffect, useState, useRef } from 'react';
import { formatCurrency } from "~/helpers/numbers";

function InputField({ id, name, label, helperMessage, formData, setFormData, touchedState, setTouchedState, formErrors, fixDigits, onChange, ...props }) {
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  let inputOptions = {};
  const errorMessage = touchedState[id] && formErrors[id];
  const helperLabelText = errorMessage || helperMessage;
  const labelText = label || (name || id).replace(/_/g, ' ')

  let wrapperClassList = 'form-group';

  function handleChange(event) {
    let value = event.target.value;

    if (fixDigits) {
      value = formatCurrency(value, fixDigits);
      const adjustment  = (value.match(/,/g) || []).length - (formData[id].match(/,/g) || []).length;
      setCursor(event.target.selectionStart + adjustment);
    }

    setTouchedState(previousState => {
      return { ...previousState, [id]: true }
    });

    setFormData(previousState => {
      return { ...previousState, [id]: value }
    });

    if (onChange) { onChange(); }
  }

  if (fixDigits) {
    inputOptions.inputMode = 'decimal';
  }

  if (errorMessage) {
    wrapperClassList += ' has-error'
  }

  useLayoutEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [cursor]);

  return(
    <div className={wrapperClassList}>
      <label className='text-capitalize' htmlFor={id || name}>{labelText}</label>
      <input ref={ref} id={id} className='react-input form-control' value={formData[id]} onChange={handleChange} {...inputOptions} {...props} />
      <label className='float-right mb-0'>{helperLabelText}</label>
    </div>
  );
}

export default InputField;
