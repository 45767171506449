import {useState, useEffect, useRef, Fragment} from 'react';
import {jsSafeFloat, formatCurrency} from '~/helpers/numbers';
import {useFormState, validateFormSubmit, userErrors} from '~/helpers/forms';
import SelectField from "~/fields/select_field";
import InputField from "~/fields/input_field";
import Button from "~/fields/button";

const totalCost = formData => jsSafeFloat(formData.amount) + jsSafeFloat(formData.fee);
const remainingBalance = (balance, formData) => Number((balance - totalCost(formData)).toFixed(2));

const selectedPaymentOptions = (beneOptions, formData) =>
    (beneOptions.payment_types && formData.payment_type) ? beneOptions.payment_types.find(pt => pt.value === formData.payment_type).data : {};
const feeReasonRequired = (beneOptions, formData) => jsSafeFloat(selectedPaymentOptions(beneOptions, formData).fee) !== jsSafeFloat(formData.fee);

const selectedTransactionReason = (beneOptions, reasonValue) => beneOptions.transaction_reasons?.find(tr => tr.value === reasonValue);

const transactionReasonOtherRequired = (beneOptions, reasonValue) => selectedTransactionReason(beneOptions, reasonValue)?.data?.is_default;

function validateForm(setFormErrors, formData, props, beneOptions) {
    let errors = {};

    if (!formData.beneficiary) {
        errors.beneficiary = I18n.t('form_labels.form.required');
    }

    if (remainingBalance(props.balance, formData) < 0.0) {
        errors.amount = I18n.t('js.insufficient_balance');
    } else if (jsSafeFloat(formData.amount) < jsSafeFloat(props.minimum_amount)) {
        errors.amount = I18n.t('js.minimum_amount', {amount: props.minimum_amount});
    }

    if (jsSafeFloat(formData.fee) < 0.0) {
        errors.fee = I18n.t('js.minimum_amount', {amount: '0.00'});
    }

    if (feeReasonRequired(beneOptions, formData) && !formData.fee_reason) {
        errors.fee_reason = I18n.t('form_labels.form.required');
    }

    if (beneOptions.transaction_reasons?.length && !formData.transaction_reason) {
        errors.transaction_reason = I18n.t('form_labels.form.required');
    }

    if (transactionReasonOtherRequired(beneOptions, formData.transaction_reason) && !formData.transaction_reason_other) {
        errors.transaction_reason_other = I18n.t('form_labels.form.required');
    }

    const paymentLimit = jsSafeFloat(selectedPaymentOptions(beneOptions, formData).limit);
    if (paymentLimit > 0 && paymentLimit < jsSafeFloat(formData.amount)) {
        errors.payment_type = I18n.t('js.transaction_limit_for_type', {limit: formatCurrency(paymentLimit)})
    }

    if (!formData.reference.match(new RegExp(props.allowed_characters))) {
        errors.reference = I18n.t('beneficiary_transaction.invalid_reference');
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
}

function updateBeneficiaryOptions(beneOptions, formData, setFormData, setSelectOptions) {
    const allowedPaymentTypes = beneOptions.payment_types.filter((pt) =>
        pt.value === formData.payment_type || !pt.data.limit || jsSafeFloat(pt.data.limit) > jsSafeFloat(formData.amount));

    setSelectOptions(previousState => {
        return {
            ...previousState,
            payment_type: allowedPaymentTypes,
            transaction_reason: beneOptions.transaction_reasons,
        }
    })

    setFormData(previousState => {
        if (!previousState.payment_type) {
            const payType = allowedPaymentTypes[0];
            return {...previousState, payment_type: payType?.value, fee: formatCurrency(payType?.data?.fee)}
        }

        const payType = allowedPaymentTypes.find(pt => pt.value === previousState.payment_type);
        const reasonType = selectedTransactionReason(beneOptions, previousState.transaction_reason);
        const typeChanged = payType?.value !== previousState.payment_type;

        return {
            ...previousState,
            payment_type: payType?.value,
            fee: formatCurrency(typeChanged ? payType?.data?.fee : previousState.fee),
            fee_reason: typeChanged ? '' : previousState.fee_reason,
            transaction_reason: reasonType?.value,
            transaction_reason_other: reasonType ? previousState.transaction_reason_other : ''
        }
    });
}

function NewPayment(props) {
    const beneOptions = useRef({});
    const benePopover = useRef(null);

    const [selectOptions, setSelectOptions] = useState({
        customer: [{value: props.customer_name, label: props.customer_name}],
        user_id: props.users,
        beneficiary: props.beneficiaries,
        payment_type: [],
        transaction_reason: [],
        fee_reason: props.fee_reasons
    });

    const [submitting, setSubmitting, formData, setFormData, touchedState, setTouchedState, formErrors, setFormErrors] = useFormState({
        customer: props.customer_name,
        user_id: props.users?.[0]?.value,
        currency: props.currency,
        beneficiary: '',
        amount: '',
        fee: '0.00',
        fee_reason: '',
        payment_type: '',
        transaction_reason: '',
        transaction_reason_other: '',
        reference: ''
    });

    const formHelpers = {formData, setFormData, touchedState, setTouchedState, formErrors}

    const confirm_modal = $('#withdrawal-confirm-modal');

    useEffect(() => {
        validateForm(setFormErrors, formData, props, beneOptions.current);
    }, [formData]);

    useEffect(() => {
        if (beneOptions.current.payment_types) {
            updateBeneficiaryOptions(beneOptions.current, formData, setFormData, setSelectOptions);
        }
    }, [formData.amount]);

    useEffect(() => {
        if (submitting) {
            showOverlay();
            showTransactionProcessingPopUp();
        } else {
            hideOverlay();
            hideTransactionProcessingPopUp();

            if (formErrors.base) {
                showFlashMessage("<div class='alert alert-danger'>" + formErrors.base + "</div>");
            } else if (Object.keys(formErrors).filter((key) => !(key in touchedState)).length > 0) {
                showFlashMessage("<div class='alert alert-danger'>" + I18n.t('js.error_in_form_submission_request_failed') + "</div>");
            }
        }
    }, [submitting]);

    const paymentDelayedMessage = selectedPaymentOptions(beneOptions.current, formData).delayed_message;
    const paymentClientName = props.back_office ? I18n.t('db.client') : I18n.t('dashboards.transactions.accept.you');

    function showBeneficiaryDetails() {
        if (usingClientWebsite()) {
            displayBeneficiaryModal(formData.beneficiary);
        }
        else {
            displayBeneficiaryToolTip(formData.beneficiary);
        }
    }

    function handleBeneficiaryChange(option) {
        beneOptions.current = props.beneficiaries.find(pt => pt.value === option.value).data;
        updateBeneficiaryOptions(beneOptions.current, formData, setFormData, setSelectOptions);

        benePopover.current?.setAttribute('data-content', '');
    }

    function handlePaymentChange(option) {
        if (!formData.fee_reason) {
            const paymentFee = selectOptions.payment_type.find(pt => pt.value === option.value).data.fee;

            setFormData(previousState => {
                return {...previousState, fee: formatCurrency(paymentFee)}
            });
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (submitting) {
            return false;
        }

        if (validateFormSubmit(setTouchedState, validateForm(setFormErrors, formData, props, beneOptions.current))) {
            confirm_modal.modal('show');
        }
    }

    function submitForm() {
        if (submitting) {
            return false;
        }
        setSubmitting(true);

        let submitData = {...formData};

        if (!feeReasonRequired(beneOptions.current, formData)) {
            delete submitData.fee;
            delete submitData.fee_reason;
        }

        fetch('/dealing/new_transactions/create_withdrawal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': props.authenticity_token
            },
            body: JSON.stringify(submitData)
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.redirect_path) {
                    window.location.href = data.redirect_path;
                } else {
                    setFormErrors(data.errors);
                    setSubmitting(false);
                }
            });

        return true;
    }

    return (
        <form className='react-form' onSubmit={handleSubmit}>
            <div id='new_withdrawal' className='col-xs-12 transaction_form well-bordered'>
                <div className='row transfer_state_left'>
                    <div className='col-sm-12'>
                        <h3>{I18n.t('js.new_currency_payment', {ccy_code: formData.currency})}</h3>
                    </div>
                    {props.back_office &&
                        <div className='col-sm-12'>
                            <div className='panel panel-default'>
                                <div className='panel-body'>
                                    <div className='col-xs-12 col-sm-6'>
                                        <SelectField
                                            id='customer'
                                            label={I18n.t('db.client')}
                                            selectOptions={selectOptions}
                                            isDisabled={true}
                                            {...formHelpers}
                                        />
                                    </div>
                                    <div className='col-xs-12 col-sm-6'>
                                        <SelectField
                                            id='user_id'
                                            label={I18n.t('db.user')}
                                            selectOptions={selectOptions}
                                            {...formHelpers}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='col-sm-12 col-lg-7'>
                        <div className='panel panel-default'>
                            <div className='panel-body row'>
                                <div className='col-xs-12 col-sm-7'>
                                    {formData.beneficiary.length > 0 &&
                                        <label className='pull-right'>
                                            <a href='#0'
                                               onClick={showBeneficiaryDetails}
                                               ref={benePopover}
                                               role='button'
                                               className='single_bene_detail_tooltip react-tooltip'
                                               data-toggle='popover'
                                               data-placement='bottom'
                                               data-trigger='focus'
                                               data-html='true'
                                               data-content=''
                                            >
                                                {I18n.t('js.show_details')}
                                            </a>
                                        </label>
                                    }
                                    <SelectField
                                        id='beneficiary'
                                        label={I18n.t('db.beneficiary')}
                                        selectOptions={selectOptions}
                                        onChange={handleBeneficiaryChange}
                                        {...formHelpers}
                                    />
                                </div>
                                <div className='col-xs-12 col-sm-7'>
                                    <InputField
                                        id='amount'
                                        label={I18n.t('js.amount_of_currency_i_want_to_send', {ccy_code: formData.currency})}
                                        fixDigits={2}
                                        helperMessage={`Remaining balance ${formData.currency} ${formatCurrency(remainingBalance(props.balance, formData))}`}
                                        placeholder='0.00'
                                        {...formHelpers}
                                    />
                                </div>
                                {beneOptions.current.payment_types?.length > 1 &&
                                    <div className='col-xs-12 col-sm-7'>
                                        <SelectField
                                            id='payment_type'
                                            label={I18n.t('beneficiary_transactions.payments.provider_payment_type')}
                                            selectOptions={selectOptions}
                                            onChange={handlePaymentChange}
                                            {...formHelpers}
                                        />
                                    </div>
                                }
                                {props.back_office &&
                                    <Fragment>
                                        <div className='col-xs-12 col-sm-7'>
                                            <InputField
                                                id='fee'
                                                label={I18n.t('helper.fee')}
                                                fixDigits={2}
                                                {...formHelpers}
                                            />
                                        </div>
                                        {feeReasonRequired(beneOptions.current, formData) &&
                                            <div className='col-xs-12 col-sm-7'>
                                                <SelectField
                                                    id='fee_reason'
                                                    label={I18n.t('transaction_histories.form.reason_for_update')}
                                                    selectOptions={selectOptions}
                                                    {...formHelpers}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                                {!!selectOptions.transaction_reason?.length &&
                                    <Fragment>
                                        <div className='col-xs-12 col-sm-7'>
                                            <SelectField
                                                id='transaction_reason'
                                                label={I18n.t('beneficiary_transactions.payments.purpose_of_payment')}
                                                selectOptions={selectOptions}
                                                {...formHelpers}
                                            />
                                        </div>
                                        {transactionReasonOtherRequired(beneOptions.current, formData.transaction_reason) &&
                                            <div className='col-xs-12 col-sm-7'>
                                                <InputField
                                                    id='transaction_reason_other'
                                                    label={I18n.t('general.please_specify')}
                                                    {...formHelpers}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                                <div className='col-xs-12 col-sm-7'>
                                    <InputField
                                        id='reference'
                                        label={I18n.t('beneficiary_transactions.payments.payment_reference')}
                                        {...formHelpers}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-5'>
                        <div className='panel panel-default'>
                            <div className='panel-heading'>
                                <h4>{I18n.t('js.payment_details')}</h4>
                            </div>

                            <div className='panel-body'>
                                <table className='table table-condensed payment-summary-table'>
                                    <tbody>
                                    <tr>
                                        <th>{I18n.t('beneficiary.beneficiary_name')}</th>
                                        <td ignore=''>{props.beneficiaries?.find(pt => pt.value === formData.beneficiary)?.label || ''}</td>
                                    </tr>
                                    <tr>
                                        <th>{I18n.t('payment_data.payment_amount')}</th>
                                        <td ignore=''>{formData.amount || ''} </td>
                                    </tr>
                                    <tr>
                                        <th>{I18n.t('helper.fee')}</th>
                                        <td ignore=''>{formData.fee || ''}</td>
                                    </tr>
                                    <tr>
                                        <th className='pt-2'>{I18n.t('receipt.total_amount')}</th>
                                        <td className='pt-2'>
                                            <var>{`${formData.currency} ${formatCurrency(totalCost(formData))}`}</var>
                                            {paymentDelayedMessage &&
                                                <Fragment>
                                                    <br/>
                                                    <span>{paymentDelayedMessage}</span>
                                                </Fragment>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <Button
                                    label={I18n.t('helpers.submit.submit')}
                                    className='btn btn-primary btn-block'
                                    disabled={userErrors(touchedState, formErrors) || submitting}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='withdrawal-confirm-modal' className='modal static_modal'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3>{I18n.t('dashboards.transactions.accept.review_transaction')}</h3>
                        </div>
                        <div className='modal-body'>
                            <div>
                                <h5>{I18n.t('dashboards.transactions.accept.by_clicking_confirm', {resource: paymentClientName})}</h5>
                                <div className='striped-rows'>
                                    <div className='row'>
                                        <div className='col-xs-6'>{I18n.t('helper.pay')}</div>
                                        <div className='col-xs-6' ignore=''>
                                            {props.beneficiaries?.find(pt => pt.value === formData.beneficiary)?.label || ''}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-6'>{I18n.t('general.amount')}</div>
                                        <div
                                            className='col-xs-6' ignore=''>{`${formData.currency} ${formatCurrency(formData.amount)}`}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-6'>{I18n.t('helper.fee')}</div>
                                        <div
                                            className='col-xs-6' ignore=''>{`${formData.currency} ${formatCurrency(formData.fee)}`}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-12'>
                                            {I18n.t('js.total_amount_will_be_withdrawn', {amount: `${formData.currency} ${formatCurrency(totalCost(formData))}`})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-xs-6'>
                                    <Button
                                        label={I18n.t('helpers.submit.confirm_transaction')}
                                        className='btn btn-primary btn-block btn-default-padding'
                                        onClick={submitForm}
                                        data-dismiss='modal'
                                        data-bs-dismiss='modal'
                                    />
                                </div>
                                <div className='col-xs-6'>
                                    <Button
                                        label={I18n.t('helpers.submit.cancel')}
                                        className='btn btn-default btn-block no-background'
                                        data-dismiss='modal'
                                        data-bs-dismiss='modal'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default NewPayment;
